import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import InHouseForm from "../components/InHouseForm";

import Layout from "../components/layout";
import { inHouseForms } from "../config/inHouseForms";
import { getTitle } from "../helpers/globalHelpers";
import { randomUid } from "../utilities/utilities";

const SubmitForm = () => {
  const [formId, setFormId] = useState();
  const [formType, setFormType] = useState();
  const [submissionId, setSubmissionId] = useState(randomUid({ length: 10 }));

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setFormId(urlParams.get("formId"));
    setFormType(urlParams.get("formType"));
  }, []);

  return (
    <Layout pageName="Submit Form">
      <p class="title">{getTitle(formType)}</p>
      <nav class="breadcrumb has-succeeds-separator" aria-label="breadcrumbs">
        <ul>
          <li>
            <Link
              class="fpBreadcrumbLink"
              to={`/selectForm?formType=${formType}`}
            >
              Select a form
            </Link>
          </li>
          <li>
            <p class="fpEndBreadcrumb">Submit form</p>
          </li>
        </ul>
      </nav>
      <InHouseForm
        config={inHouseForms[formId]}
        submissionId={submissionId}
        isSubmit={true}
      />
    </Layout>
  );
};

export default SubmitForm;
